$(document).ready(function(){
	$(window).load(function(){

		AOS.init({
			delay: 300,
			once: true,
		});

		// Global Variables
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.site-header').outerHeight(),
		$quickLinks = $('nav.site-quicklinks').outerHeight(),
		$footerHeight = $('footer.site-footer').outerHeight();

		var themeDir = $('.usd').attr('data-theme');
		particlesJS.load('banner-particles', themeDir+'/assets/js/json/particles.json', function() {});

		headerPushTop();
		
		$('.main-panel').css({
			'min-height': $pageHeight + $navHeight,
			'margin-bottom' : $quickLinks,
			// 'margin-top': $navHeight,
			'padding-bottom': $footerHeight
		});

		$('.back-to-top').hide();
		
		$('.back-to-top').css({
			'bottom' : $quickLinks + 35
		});

		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				$('.back-to-top').fadeIn();
			} else {
				$('.back-to-top').fadeOut();
			}
		});
		$('.back-to-top a, .mobile-back-to-top a').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			return false;
		});

		$('.loader-overlay').fadeOut(200);

		$('.mailto-container .mailto-contain, .career-container .career-contain').css({
			'padding-bottom' : $quickLinks,
			'padding-top' : $navHeight
		});
		$(window).scroll(function () {
			if( $('header.site-header').hasClass('push-top') ) {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : 0
				});
			} else {
				$('.mailto-container .mailto-contain, .career-container .career-contain').css({
					'padding-bottom' : $quickLinks,
					'padding-top' : $navHeight
				});
			}
		});

		// Sub Menus
		$('.menu-container .menu-contain nav ul li.menu-item-has-children').each(function(){
			$(this).append('<button type="button" class="submenu-button"><i class="fas fa-angle-right"></i></button>');
		});
		$('.menu-container .menu-contain nav ul li .sub-menu').each(function(){
			$(this).prepend('<li><button type="button" class="submenu-close-button"><i class="fas fa-angle-left"></i>Back</button></li>');
		});

		if( $(window).width() <= 1199 ) {
			$('.client-list').slick({
			  dots: false,
			  infinite: false,
			  speed: 300,
			  slidesToShow: 4,
			  slidesToScroll: 4,
			  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  	nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
			  responsive: [
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			      }
			    },
			    {
			      breakpoint: 600,
			      settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
			      }
			    }
			    // You can unslick at a given breakpoint now by adding:
			    // settings: "unslick"
			    // instead of a settings object
			  ]
			});
		}

	});
});

function headerPushTop(){
	var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var header = $('header.site-header');

  var checkScroll = function() {

    /*
    ** Find the direction of scroll
    ** 0 - initial, 1 - up, 2 - down
    */

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (curScroll < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if( curScroll > 52 ) {
  		header.addClass('scrolled');
  	}
  	if( curScroll < 52 ) {
  		header.removeClass('scrolled');
  	}

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }
    
    prevScroll = curScroll;
  };

  var toggleHeader = function(direction, curScroll) {
    if (direction === 2 && curScroll > 52) {
      header.addClass('push-top');
      prevDirection = direction;
    }
    else if (direction === 1) {
      header.removeClass('push-top');

      prevDirection = direction;
    }
  };
  
  window.addEventListener('scroll', checkScroll);
}